<template>
	<el-dialog
		v-dialogDrag
		:modal="true"
		append-to-body
		width="60vw"
		title="Weather"
		:visible="true"
		:close-on-click-modal="false"
		top="2.5vh"
		@close="close"
	>
		<div class="content">
			<div class="dialog-content" ref="Wind"></div>
			<div class="dialog-content" ref="Swell"></div>
			<div class="dialog-content" ref="Wave"></div>
			<div class="dialog-content" ref="Current"></div>
		</div>
	</el-dialog>
</template>

<script>
var directionMap = {};
echarts.util.each(
	['W', 'WSW', 'SW', 'SSW', 'S', 'SSE', 'SE', 'ESE', 'E', 'ENE', 'NE', 'NNE', 'N', 'NNW', 'NW', 'WNW'],
	function (name, index) {
		directionMap[name] = (Math.PI / 8) * index;
	},
);
var dims = {
	time: 0,
	windSpeed: 1,
	R: 2,
	waveHeight: 3,
	weatherIcon: 2,
	minTemp: 3,
	maxTemp: 4,
};
var arrowSize = 18;
var weatherIconSize = 45;
import * as echarts from 'echarts';
export default {
	props: {
		weatherMasterData: {
			type: Array,
			default: () => [],
		},
		masterReportData: {
			type: Array,
			default: () => [],
		},
		masterTimeList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			weatherList: [
				{
					id: 'Wind',
					label: 'Wind speed（BF）',
					weatherValue: 'windSpeed',
					masterValue: 'windBeaufort',
					masterDir: 'windDirection',
					dir: 'windDir',
					data: [],
					masterData: [],
				},
				{
					id: 'Swell',
					label: 'Swell height(m)',
					weatherValue: 'swellHt',
					masterValue: 'swellHeight',
					masterDir: 'swellDirection',
					dir: 'swellDir',
					data: [],
					masterData: [],
				},
				{
					id: 'Wave',
					label: 'Significant Wave height (m)',
					weatherValue: 'sigHgt',
					data: [],
				},
				{
					id: 'Current',
					label: 'Current factor(kts)',
					weatherValue: 'cf',
					data: [],
				},
			],
			nowTime: null,
		};
	},
	mounted() {
		console.log(1111111, this.masterTimeList);
		// this.nowTime = this.getTime();
		this.nowTime = 1708657200000;
		this.weatherList.forEach((echartData, index) => {
			this.weatherMasterData.forEach((data) => {
				if (data[echartData.weatherValue] < 999) {
					// if (!this.nowTime || ((this.nowTime - time) < (new Date(data.dateTime) - time) && new Date(data.dateTime) - time < 0)) {
					// 	this.nowTime = new Date(data.dateTime)
					// }
					echartData.data.push([
						data.dateTime,
						data[echartData.weatherValue],
						echartData?.dir ? data[echartData.dir] : 0,
					]);
				}
			});
			if (echartData.masterData) {
				this.masterReportData.forEach((e) => {
					let data = e.masterReportInfo;
					if (['EOSP', 'NOON'].includes(data.typeSon)) {
						echartData.masterData.push([data.dateUtc, data[echartData.masterValue]]);
					}
				});
			}
		});
		console.log(this.weatherList);
		this.$nextTick((e) => {
			this.weatherList.forEach((echartData) => {
				// this.masterTimeList.forEach((time) => {
				// 	if
				// 	echartData.data.push([time, 0, 0]);
				// 	if (echartData.masterData) {

				// 		echartData.masterData.push([time, 0]);
				// 	}
				// });
				this.addEcharts(echartData);
			});
		});
	},
	methods: {
		getTime() {
			// 获取当前时间
			let now = new Date();
			return now;
		},
		addEcharts(weatherData) {
			let that = this;
			let data = weatherData.data;
			let reportData = weatherData.masterData;
			let series = weatherData.dir
				? [
						{
							type: 'custom',
							renderItem: renderArrow,
							encode: {
								x: 0,
								y: 1,
							},
							data: data,
							tooltip: {
								show: false, // 禁用 pictorialBar 系列的 tooltip
							},
							z: 100,
						},
						{
							name: 'Analysis ：',
							type: 'line',
							data: data.map((item) => {
								if (new Date(item[0]) <= that.nowTime) {
									return item;
								}
							}),
							itemStyle: {
								color: '#65BB73', // 用您希望的默认颜色替换 'default_color'
							},
							showSymbol: false,
							z: 2,
						},
						{
							name: 'Forecast :',
							type: 'line',
							data: data,
							showSymbol: false,
							itemStyle: {
								color: '#2662D5', // 用您希望的默认颜色替换 'default_color'
							},
							z: 1,
						},
						{
							name: 'Master : ',
							type: 'line',
							data: reportData,
							symbolSize: 8,
							lineStyle: {
								width: 3, // 设置线宽
								color: 'red',
								type: 'dashed',
							},
							itemStyle: {
								color: 'red',
							},
							z: 3,
						},
				  ]
				: [
						{
							name: 'Analysis ：',
							type: 'line',
							data: data.map((item) => {
								if (new Date(item[0]) <= that.nowTime) {
									return item;
								}
							}),
							itemStyle: {
								color: '#65BB73', // 用您希望的默认颜色替换 'default_color'
							},
							showSymbol: false,
							z: 2,
						},
						{
							name: 'Forecast :',
							type: 'line',
							data: data,
							showSymbol: false,
							itemStyle: {
								color: '#2662D5', // 用您希望的默认颜色替换 'default_color'
							},
							z: 1,
						},
				  ];
			//   渲染箭头
			function renderArrow(param, api) {
				var point = api.coord([api.value(dims.time), api.value(dims.windSpeed)]);
				point[1] = 20;
				var time = api.value(0); // 获取时间值
				// 判断时间，选择颜色
				var color;
				if (time <= +new Date(that.nowTime)) {
					color = '#65BB73';
				} else {
					color = '#2662D5';
				}
				return {
					type: 'path',
					shape: {
						pathData:
							'M6 15.5833L3.75 15.5833L3.75 2.13799e-08L2.25 3.65381e-08L2.25 15.5833L2.75787e-07 15.5833L3 22L6 15.5833Z',
						x: -arrowSize / 2,
						y: -arrowSize / 2,
						width: arrowSize,
						height: arrowSize,
					},
					rotation: api.value(dims.R),
					position: point,
					style: api.style({
						stroke: color,
						fill: color, // 使用之前选择的颜色
						lineWidth: 1,
					}),
				};
			}
			let chart = echarts.init(this.$refs[weatherData.id]);
			let option = {
				tooltip: {
					trigger: 'axis',
					// formatter: function (params) {
					// 	return [
					// 		'Time：' +  echarts.format.formatTime('yyyy-MM-dd', params[0].value[dims.time]) +
					// 			' ' +	echarts.format.formatTime('hh:mm', params[0].value[dims.time]),
					// 		weatherData.label+ '：' + params[0].value[dims.windSpeed],
					// 		weatherData.label+ '：' + params[0].value[dims.R],
					// 	].join('<br>');
					// },
				},
				// dataZoom: [
				// 	{
				// 		type: 'inside',
				// 		xAxisIndex: 0,
				// 		minSpan: 5,
				// 	},
				// 	{
				// 		type: 'slider',
				// 		xAxisIndex: 0,
				// 		minSpan: 5,
				// 		height: 20,
				// 		bottom: 50,
				// 		handleIcon:
				// 			'M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
				// 		handleSize: '120%',
				// 	},
				// ],
				// 移除图例
				legend: {
					show: false,
				},
				grid: {
					top: 40,
					left: 50,
					right: 20,
					bottom: 20,
				},
				xAxis: {
					type: 'time',
					axisLine: {
						show: false, // 不显示坐标轴线
					},
					splitLine: {
						show: true, // 不显示坐标轴线
						lineStyle: {
							color: '#ddd',
						},
					},
					axisTick: {
						show: false,
					},
					// data: this.masterTimeList,
					axisLabel: {
						formatter: function (value) {
							// 在这里自定义时间格式
							var date = new Date(value);
							var year = date.getFullYear();
							var month = date.getMonth() + 1;
							var day = date.getDate();
							return year + '-' + month + '-' + day;
						},
					},
					splitNumber: 8, // 如果不使用 interval，你可以尝试 splitNumber 属性来大约分割时间轴（例如3小时间隔，24小时/3 = 8段）
					min: this.masterTimeList[0], // 指定横坐标轴最小值
					max: this.masterTimeList[this.masterTimeList.length-1], // 指定横坐标轴最大值
				},
				yAxis: [
					{
						name: weatherData.label,
						nameLocation: 'middle',
						nameGap: 35,
						axisLine: {
							lineStyle: {
								color: '#666',
							},
						},
						splitLine: {
							lineStyle: {
								color: '#ddd',
							},
						},
					},
				],
				series: series,
			};
			chart.setOption(option);
		},
		close() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="less">
.content {
	width: 100%;

	.dialog-content {
		width: 100%;
		height: 190px;
		justify-content: space-between;
		align-items: flex-start;
	}
}
</style>
